.card {
    border-radius: 0.5rem; /* Bo góc card */
}

.card-body {
    padding: 2rem; /* Padding card-body */
}

.btn-primary {
    background-color: #007bff; /* Màu nền button */
    border-color: #007bff; /* Màu viền button */
}

.btn-primary:hover {
    background-color: #0056b3; /* Màu nền khi hover */
    border-color: #004085; /* Màu viền khi hover */
}

.text-success {
    color: #28a745; /* Màu chữ thông báo thành công */
}

.text-danger {
    color: #dc3545; /* Màu chữ thông báo lỗi */
}
