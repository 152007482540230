.calendar-dropdowns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.react-calendar button {
    padding-top: 20px;
    padding-bottom: 20px;
}

.react-calendar {
    width: 100%;
}

.days-of-week {
    display: flex;
}

.day-header {
    flex: 1;
    text-align: center;
    font-weight: bold;
}

.calendar-days {
    display: flex;
    flex-wrap: wrap;
}

.empty-day {
    flex: 1;
    height: 20px;
}

.calendar-day {
    flex: 1;
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    cursor: pointer;
}

.calendar-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
}

h2 {
    text-align: center;
}

/* Add this CSS to highlight work days in blue */
.work-day {
    background-color: lightblue;
    padding: 5px;
}

.day-off {
    padding: 5px;
    background-color: #FFA07A;
    text-color: #FFFFE0;
}
.loader {
    border: 8px solid #f3f3f3;
    /* Light grey */
    border-top: 8px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
